import { gql, useQuery } from '@apollo/client'
import { apolloClient } from 'graphql/thegraph/apollo'
import { useMemo } from 'react'

const GET_DOGS = gql`
  query Pools($timestamp: Int!, $sort: OrderDirection, $skip: Int!, $first: Int!) {
    pools(orderBy: totalValueLockedUSD, orderDirection: $sort, skip: $skip, first: $first) {
      id
      token0 {
        id
        symbol
        decimals
        derivedETH
      }
      feesUSD
      feeTier
      volumeToken0
      volumeToken1
      token1 {
        id
        symbol
        decimals
        derivedETH
      }
      totalValueLockedUSD
      totalValueLockedToken0
      totalValueLockedToken1
      volumeUSD
      poolHourData(where: { periodStartUnix_gt: $timestamp }) {
        periodStartUnix
        volumeUSD
      }
    }

    bundles(where: { id: "1" }) {
      ethPriceUSD
      __typename
    }
  }
`

const GET_DOC_2 = gql`
  query Pools {
    pools(orderDirection: desc) {
      id
      token0 {
        id
        symbol
        decimals
      }
      token1 {
        id
        symbol
        decimals
      }
    }
  }
`

export interface PoolResponse {
  id: string
  totalValueLockedUSD: string
  volumeUSD: string
  token0: {
    id: string
    symbol: string
    decimals: string
    derivedETH: string
  }
  token1: {
    id: string
    symbol: string
    decimals: string
    derivedETH: string
  }
  feesUSD: string
  poolHourData: {
    periodStartUnix: number
    volumeUSD: string
  }[]
  feeTier?: string
  volumeToken0: string
  volumeToken1: string
  totalValueLockedToken0: string
  totalValueLockedToken1: string
}

export interface ItemTableAllPool extends PoolResponse {
  volume7D: string
  tvlUSD: number
}

interface AllPoolResponse {
  pools: PoolResponse[]
  bundles: [
    {
      ethPriceUSD: string
      __typename: string
    }
  ]
}

export function useQueryAllPool(sort: 'asc' | 'desc') {
  const sevenday = useMemo(() => {
    return Math.floor(Date.now() / 1000) - 7 * 24 * 60 * 60
  }, [])

  const data = useQuery<AllPoolResponse>(GET_DOGS, {
    client: apolloClient,
    variables: {
      timestamp: sevenday,
      sort,
      skip: 0,
      first: 20,
    },
  })

  return data
}

export function useAllPoolId() {
  const data = useQuery<AllPoolResponse>(GET_DOC_2, {
    client: apolloClient,
  })

  return data
}
