import clsx from 'clsx'
import { memo, useMemo, useState } from 'react'
import { ChevronRight } from 'react-feather'
import { InterfaceTrade } from 'state/routing/types'
import { SwapRoutingModal } from './SwapRoutingModal'
import { formatProtocol, getLower } from './format-protocol'
import { ProtocolResponse } from './type'

function SwapRoutingComponent({ trade }: { trade: InterfaceTrade & ProtocolResponse }) {
  const [visible, setVisible] = useState(false)

  const routing_hop = useMemo(() => {
    if (!trade.routes) {
      return {
        text: '',
        protocol: [],
      }
    }

    const protocol = []

    for (const elm of trade.routes) {
      for (const child of elm.swaps) {
        protocol.push(child.protocol)
      }
    }

    return {
      text: ``,
      protocol,
    }
  }, [trade.routes])

  return (
    <>
      <div
        onClick={() => setVisible((v) => !v)}
        className="flex items-center justify-between gap-[34px] w-full lg:max-w-[432px] cursor-pointer"
      >
        <div className="flex-1 flex items-center gap-[8px]">
          <p className="text-sm tracking-[-0.28px] text-[#808080]">Routing</p>
        </div>

        <div className="flex items-center">
          <p className={clsx('flex-1 line-clamp-1 text-sm tracking-[-0.28px] text-[#808080]')}>
            {routing_hop.protocol.map((item, idx) => {
              return (
                <span key={idx} className={!getLower(item) ? 'capitalize' : ''}>
                  {idx === 0 ? '' : ','} {formatProtocol(item)}
                </span>
              )
            })}
          </p>

          <ChevronRight stroke="#808080" />
        </div>
      </div>

      {visible && trade.routes && <SwapRoutingModal visible={visible} setVisible={setVisible} data={trade} />}
    </>
  )
}

export const SwapRouting = memo(SwapRoutingComponent)
