import questionMark from 'assets/svg/question-mark.svg'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import { useGetTokens } from 'components/AccountDrawer/MiniPortfolio/NewTokens/data'
import { ethers } from 'ethers'
import { useGetRate } from 'hooks/useGetRateTokens'
import { NATIVE_ADDRESS } from 'lib/SwapRouter'
import { formatDisplay } from 'pages/Swap/Chart/format-balance'
import { useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { PAYMASTER_LIST } from 'state/paymaster/initStore'
import { PayMasterItem, setPaymasterTokenSelect } from 'state/paymaster/slices'
import { AppState } from 'state/reducer'

interface Item extends PayMasterItem {
  amount: string
  quote_rate: string
  contract_decimals: number
}

type Props = {
  gasCost: ethers.BigNumber
  onClose: () => void
}

export function TokenPaymasterList({ gasCost, onClose }: Props) {
  const rateList = useGetRate()

  const paymasterTokenSelect = useAppSelector((state: AppState) => state.paymasterStoreReducer.paymasterTokenSelect)
  const dispatch = useAppDispatch()
  const { data } = useGetTokens()

  const formatData = useMemo(() => {
    const filter = PAYMASTER_LIST.reduce((result, value) => {
      const token = data?.find((v) => v.contract_address.toLowerCase() === value.address.toLowerCase())

      if (token) {
        result.push({
          ...value,
          amount: token.amount,
          quote_rate: token.quote_rate,
          contract_decimals: token.contract_decimals,
        })
      } else {
        result.push({
          ...value,
          amount: '0',
          quote_rate: '0',
          contract_decimals: 0,
        })
      }

      return result
    }, [] as Item[])

    return filter.map((item) => {
      const amount = new BigNumber(item.amount).dividedBy(Math.pow(10, item.contract_decimals ?? 18))
      const amountBalance = amount.multipliedBy(new BigNumber(item.quote_rate))
      return {
        ...item,
        formatAmount: formatDisplay(amount.toNumber(), { decimalToShow: 5 }),
        amountBalance: isNaN(amountBalance.toNumber())
          ? '0'
          : formatDisplay(amountBalance.toNumber(), { decimalToShow: 5 }),
        amountBalanceNumber: amount.toNumber(),
      }
    })
  }, [data])

  const quote_rate_native = data?.find((item) => item.contract_address.toLowerCase() === NATIVE_ADDRESS)?.quote_rate

  const handleSelect = (item: Item) => {
    const data = PAYMASTER_LIST.find((value) => value.address.toLowerCase() === item.address.toLowerCase())

    if (!data) {
      return
    }
    dispatch(setPaymasterTokenSelect(data))
    onClose()
  }

  if (!formatData || !rateList) {
    return <></>
  }
  return (
    <div className="flex flex-col gap-[32px] mb-10 mx-4">
      {formatData.map((item, idx) => {
        const logo_url = item.imageUrl ?? questionMark

        const gas = Number(parseFloat(ethers.utils.formatEther(gasCost)) * Number(quote_rate_native))

        const gasAmount =
          item.address.toLowerCase() === NATIVE_ADDRESS
            ? gas / Number(quote_rate_native)
            : parseFloat(ethers.utils.formatEther(gasCost)) * rateList[item.address.toLowerCase()].after

        const discount =
          item.address.toLowerCase() === NATIVE_ADDRESS
            ? 1
            : rateList[item.address.toLowerCase()].after / rateList[item.address.toLowerCase()].before

        const gasUsd = formatDisplay(Number(gas * discount) || 0, {
          decimalToShow: paymasterTokenSelect.decimalsToShow,
        })
        const gasAmountFormat = formatDisplay(gasAmount, { decimalToShow: paymasterTokenSelect.decimalsToShow })
        const isDisabled = gasAmount > item.amountBalanceNumber
        return (
          <div
            // disabled={isDisabled}
            key={idx}
            onClick={() => {
              if (isDisabled) {
                return
              }
              handleSelect(item)
            }}
            className={clsx(
              'flex items-center justify-between gap-3 px-[24px] py-3 rounded-lg  border border-solid border-transparent hover:border-yellow transition-all cursor-pointer',
              {
                '!border-[#DBFF73]': paymasterTokenSelect.address.toLowerCase() === item.address.toLowerCase(),
                'opacity-30': isDisabled,
              }
            )}
          >
            <div className="w-9 aspect-square rounded-full overflow-hidden">
              <img src={logo_url} alt="icon token" width={36} height={36} loading="lazy" />
            </div>

            <div className="flex-1 flex flex-col gap-[6px]">
              <p className="text-[16px] leading-4 font-medium tracking-[-0.32px]">{item.symbol}</p>
              <p className="text-[16px] leading-4 font-medium tracking-[-0.32px]">{item.formatAmount}</p>
            </div>

            <div className="flex flex-col items-end gap-[6px]">
              <p className="text-[16px] leading-4 font-medium tracking-[-0.32px]">{gasAmountFormat}</p>
              <p className="text-[12px] leading-3 text-[#808080] tracking-[-0.28px]">${gasUsd}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
