import Breadcrumbs from './Breadcrumbs'
import { PoolOverview } from './PoolsOverview'
import { Transaction } from './Transaction'
import Header from './header'

export function AllPoolDetail() {
  return (
    <div className="w-full max-w-[1232px] px-[16px] mx-auto mt-[64px] ">
      <Breadcrumbs />

      <Header />

      <PoolOverview />

      <Transaction />
    </div>
  )
}
