import { formatBalance } from 'pages/Pool/all-pool'
import { PoolData } from '../type'

interface CustomToolTipProps {
  chartProps: any
  poolData: PoolData
  currentPrice?: number
}

export function CustomToolTip({ chartProps, poolData, currentPrice }: CustomToolTipProps) {
  const price0 = chartProps?.payload?.[0]?.payload.price0
  const price1 = chartProps?.payload?.[0]?.payload.price1
  const tvlToken0 = chartProps?.payload?.[0]?.payload.tvlToken0
  const tvlToken1 = chartProps?.payload?.[0]?.payload.tvlToken1

  return (
    <div className="w-[320px] opacity-60 bg-[#1f2128] p-3 rounded-lg">
      <div>
        <p className="text-xs">Tick stats</p>
        <div className="flex items-center justify-between">
          <p className="text-sm">{poolData?.token0?.symbol} Price: </p>
          <p className="text-sm">
            {price0
              ? Number(price0).toLocaleString(undefined, {
                  minimumSignificantDigits: 1,
                })
              : ''}{' '}
            {poolData?.token1?.symbol}
          </p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-sm">{poolData?.token1?.symbol} Price: </p>
          <p className="text-sm">
            {price1
              ? Number(price1).toLocaleString(undefined, {
                  minimumSignificantDigits: 1,
                })
              : ''}{' '}
            {poolData?.token0?.symbol}
          </p>
        </div>
        {currentPrice && price0 && currentPrice > price1 ? (
          <div className="flex items-center justify-between">
            <p className="text-sm">{poolData?.token0?.symbol} Locked: </p>
            <p className="text-sm">
              {tvlToken0 ? formatBalance(tvlToken0) : ''} {poolData?.token0?.symbol}
            </p>
          </div>
        ) : (
          <div className="flex items-center justify-between">
            <p className="text-sm">{poolData?.token1?.symbol} Locked: </p>
            <p className="text-sm">
              {tvlToken1 ? formatBalance(tvlToken1) : ''} {poolData?.token1?.symbol}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomToolTip
