import clsx from 'clsx'
import { useCallback, useContext, useMemo } from 'react'
import { SwapProvider } from 'state/swap/SwapContext'
import { useSwapContext } from 'state/swap/hooks'
import chart from '../../../assets/images/chart_v2.svg'
import { ProtocolResponse } from '../SwapRouting/type'

export function ButtonChart() {
  const { setState } = useContext(SwapProvider)
  const {
    derivedSwapInfo: {
      trade: { trade },
    },
  } = useSwapContext()

  const address = useMemo(() => {
    const newTrade: ProtocolResponse = trade as any

    if (!newTrade?.routes) {
      return ''
    }

    if (newTrade.routes.length > 1) {
      return ''
    }

    if (newTrade.routes[0].swaps.length > 1) {
      return ''
    }

    return newTrade.routes[0].swaps[0].pool
  }, [trade])

  const toggleHideChart = useCallback(() => {
    setState((v) => {
      return {
        ...v,
        chart: !v.chart,
      }
    })
  }, [setState])

  return (
    <button
      disabled={!address}
      onClick={toggleHideChart}
      className={clsx('px-[20px] py-[10px] rounded-full bg-[#121212]', {
        'opacity-30': !address,
      })}
    >
      <img src={chart} alt="chart icon" width={20} height={20} loading="lazy" />
    </button>
  )
}
