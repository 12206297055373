import { ApolloClient, NormalizedCacheObject, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import { useEffect, useMemo, useState } from 'react'
import { blockClient, client } from './client'
import { splitQuery } from './splitQuery'

export interface ProtocolData {
  // volume
  volumeUSD: number
  volumeUSDChange: number
  volumeMonthUSD: number

  // in range liquidity
  tvlUSD: number
  tvlUSDChange: number

  // fees
  feesUSD: number
  feeChange: number

  // transactions
  txCount: number
  txCountChange: number
}

export const GET_BLOCKS = (timestamps: string[]) => {
  let queryString = 'query blocks {'
  queryString += timestamps.map((timestamp) => {
    return `t${timestamp}:blocks(first: 1, orderBy: timestamp, orderDirection: desc, where: { timestamp_gt: ${timestamp}, timestamp_lt: ${
      timestamp + 600
    } }) {
        number
      }`
  })
  queryString += '}'
  return gql(queryString)
}

/**
 * for a given array of timestamps, returns block entities
 * @param timestamps
 */
export function useBlocksFromTimestamps(
  timestamps: number[],
  blockClientOverride?: ApolloClient<NormalizedCacheObject>
): {
  blocks?: {
    timestamp: string
    number: any
  }[]
  error: boolean
} {
  const activeNetwork = {
    id: 324,
  }

  const [blocks, setBlocks] = useState<any>()
  const [error, setError] = useState(false)

  const activeBlockClient = blockClientOverride ?? blockClient

  // derive blocks based on active network
  const networkBlocks = blocks?.[activeNetwork.id]

  useEffect(() => {
    async function fetchData() {
      const results = await splitQuery(GET_BLOCKS, activeBlockClient, [], timestamps)
      if (results) {
        setBlocks({ ...(blocks ?? {}), [activeNetwork.id]: results })
      } else {
        setError(true)
      }
    }
    if (!networkBlocks && !error) {
      fetchData()
    }
  })

  const blocksFormatted = useMemo(() => {
    if (blocks?.[activeNetwork.id]) {
      const networkBlocks = blocks?.[activeNetwork.id]
      const formatted = []
      for (const t in networkBlocks) {
        if (networkBlocks[t].length > 0) {
          const number = networkBlocks[t][0]['number']
          const deploymentBlock = 0
          const adjustedNumber = number > deploymentBlock ? number : deploymentBlock

          formatted.push({
            timestamp: t.split('t')[1],
            number: adjustedNumber,
          })
        }
      }
      return formatted
    }
    return undefined
  }, [activeNetwork.id, blocks])

  return {
    blocks: blocksFormatted,
    error,
  }
}

/**
 * get standard percent change between two values
 * @param {*} valueNow
 * @param {*} value24HoursAgo
 */
export const getPercentChange = (valueNow: string | undefined, value24HoursAgo: string | undefined): number => {
  if (valueNow && value24HoursAgo) {
    const change = ((parseFloat(valueNow) - parseFloat(value24HoursAgo)) / parseFloat(value24HoursAgo)) * 100
    if (isFinite(change)) return change
  }
  return 0
}

export function useDeltaTimestamps(): [number, number, number, number] {
  const utcCurrentTime = dayjs()
  const t1 = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()
  const t2 = utcCurrentTime.subtract(2, 'day').startOf('minute').unix()
  const tWeek = utcCurrentTime.subtract(1, 'week').startOf('minute').unix()
  const tMonth = utcCurrentTime.subtract(30, 'day').startOf('minute').unix()

  return [t1, t2, tWeek, tMonth]
}

export const GLOBAL_DATA = (block?: string) => {
  const queryString = `query uniswapFactories {
      factories(
       ${block !== undefined ? `block: { number: ${block}}` : ``} 
       first: 1, subgraphError: allow) {
        txCount
        totalVolumeUSD
        totalFeesUSD
        totalValueLockedUSD
      }
    }`
  return gql(queryString)
}

interface GlobalResponse {
  factories: {
    txCount: string
    totalVolumeUSD: string
    totalFeesUSD: string
    totalValueLockedUSD: string
  }[]
}

export function useFetchProtocolData(
  dataClientOverride?: ApolloClient<NormalizedCacheObject>,
  blockClientOverride?: ApolloClient<NormalizedCacheObject>
): {
  loading: boolean
  error: boolean
  data?: ProtocolData
} {
  // get appropriate clients if override needed
  // const { dataClient, blockClient } = useClients()
  const activeDataClient = dataClientOverride ?? client
  const activeBlockClient = blockClientOverride ?? blockClient

  // Aggregate TVL in inaccurate pools. Offset Uniswap aggregate TVL by this amount.
  // const tvlOffset = useTVLOffset()

  // get blocks from historic timestamps
  const [t24, t48, tWeek, tMonth] = useDeltaTimestamps()

  const { blocks, error: blockError } = useBlocksFromTimestamps([t24, t48, tMonth], activeBlockClient)
  const [block24, block48, blockMonth] = blocks ?? []

  // fetch all data
  const { loading, error, data } = useQuery<GlobalResponse>(GLOBAL_DATA(), { client: activeDataClient })

  const {
    loading: loading24,
    error: error24,
    data: data24,
  } = useQuery<GlobalResponse>(GLOBAL_DATA(block24?.number ?? 0), { client: activeDataClient })

  const {
    loading: loading48,
    error: error48,
    data: data48,
  } = useQuery<GlobalResponse>(GLOBAL_DATA(block48?.number ?? 0), { client: activeDataClient })

  const {
    loading: loadingMonth,
    error: errorMonth,
    data: dataMonth,
  } = useQuery<GlobalResponse>(GLOBAL_DATA(blockMonth?.number ?? 0), { client: activeDataClient })

  const anyError = Boolean(error || error24 || error48 || blockError || errorMonth)
  const anyLoading = Boolean(loading || loading24 || loading48 || loadingMonth)

  const parsed = data?.factories?.[0]
  const parsed24 = data24?.factories?.[0]
  const parsed48 = data48?.factories?.[0]
  const parsedMonth = dataMonth?.factories?.[0]

  const formattedData: ProtocolData | undefined = useMemo(() => {
    // if (anyError || anyLoading || !parsed || !blocks || tvlOffset === undefined) {
    if (anyError || anyLoading || !parsed || !blocks) {
      return undefined
    }

    // volume data
    const volumeUSD =
      parsed && parsed24
        ? parseFloat(parsed.totalVolumeUSD) - parseFloat(parsed24.totalVolumeUSD)
        : parseFloat(parsed.totalVolumeUSD)

    const volumeMonthUSD =
      parsed && parsedMonth
        ? parseFloat(parsed.totalVolumeUSD) - parseFloat(parsedMonth.totalVolumeUSD)
        : parseFloat(parsed.totalVolumeUSD)

    const volumeOneWindowAgo =
      parsed24?.totalVolumeUSD && parsed48?.totalVolumeUSD
        ? parseFloat(parsed24.totalVolumeUSD) - parseFloat(parsed48.totalVolumeUSD)
        : undefined

    const volumeUSDChange =
      volumeUSD && volumeOneWindowAgo ? ((volumeUSD - volumeOneWindowAgo) / volumeOneWindowAgo) * 100 : 0

    // total value locked
    const tvlUSDChange = getPercentChange(parsed?.totalValueLockedUSD, parsed24?.totalValueLockedUSD)

    // 24H transactions
    const txCount =
      parsed && parsed24 ? parseFloat(parsed.txCount) - parseFloat(parsed24.txCount) : parseFloat(parsed.txCount)

    const txCountOneWindowAgo =
      parsed24 && parsed48 ? parseFloat(parsed24.txCount) - parseFloat(parsed48.txCount) : undefined

    const txCountChange =
      txCount && txCountOneWindowAgo ? getPercentChange(txCount.toString(), txCountOneWindowAgo.toString()) : 0

    const feesOneWindowAgo =
      parsed24 && parsed48 ? parseFloat(parsed24.totalFeesUSD) - parseFloat(parsed48.totalFeesUSD) : undefined

    const feesUSD =
      parsed && parsed24
        ? parseFloat(parsed.totalFeesUSD) - parseFloat(parsed24.totalFeesUSD)
        : parseFloat(parsed.totalFeesUSD)

    const feeChange =
      feesUSD && feesOneWindowAgo ? getPercentChange(feesUSD.toString(), feesOneWindowAgo.toString()) : 0

    return {
      volumeUSD,
      volumeUSDChange: typeof volumeUSDChange === 'number' ? volumeUSDChange : 0,
      tvlUSD: parseFloat(parsed?.totalValueLockedUSD),
      tvlUSDChange,
      feesUSD,
      feeChange,
      txCount,
      txCountChange,
      volumeMonthUSD,
    }
  }, [anyError, anyLoading, blocks, parsed, parsed24, parsed48, parsedMonth])
  return {
    loading: anyLoading,
    error: anyError,
    data: formattedData,
  }
}

export function useProtocolData() {
  const { data: fetchedProtocolData, error, loading } = useFetchProtocolData()

  return { fetchedProtocolData, loading, error }
}
