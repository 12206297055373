import apple from 'assets/images/apple.svg'
import chPlay from 'assets/images/ch-play.svg'
import download from 'assets/images/download.svg'
import qrcode from 'assets/images/qr-code-styling.jpeg'
import close from 'assets/svg/close.svg'
import { ModalCenter } from 'components/Modal/ModalV2'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export function GetApp() {
  const [visible, setVisible] = useState(false)

  function toggle() {
    setVisible((v) => !v)
  }

  return (
    <>
      <div
        onClick={toggle}
        className="flex items-center justify-between gap-[12px] cursor-pointer p-[16px] rounded-[12px] bg-[#1C1C1C]"
      >
        <div className="w-[40px] h-[40px] flex items-center justify-center bg-white/10 rounded-[10px]">
          <img src={download} alt="holdstation wallet icon" width={24} height={24} loading="lazy" />
        </div>

        <div className="flex-1 flex flex-col">
          <p className="text-base">Get Holdstation App</p>

          <p className="text-xs text-[#808080]">Available on iOS & Android</p>
        </div>
      </div>

      <ModalCenter isVisible={visible} setIsVisible={toggle} className="max-w-[464px] md:min-w-[464px] bg-[#121212]">
        <div className="rounded-2xl">
          <div className="mb-6 flex items-start md:items-center justify-between">
            <p className="text-xl text-white">
              Scan to <br /> Download Holdstation Wallet
            </p>
            <button onClick={toggle}>
              <img src={close} alt="close" width={24} height={24} loading="lazy" />
            </button>
          </div>

          <div className="flex flex-col-reverse md:flex-row items-center gap-10 justify-between">
            <div className="w-full md:w-auto flex flex-col gap-3 flex-1 items-stretch">
              <Link
                to="https://apps.apple.com/us/app/holdstation-web3-wallet/id6444925618"
                target="_blank"
                className="px-6 py-[22px] flex justify-center items-center gap-2 bg-[#1C1C1C] rounded-full"
              >
                <img src={apple} alt="apple" width={21} height={20} loading="lazy" />
                <p className="text-sm font-medium text-white">App Store</p>
              </Link>

              <Link
                to="https://play.google.com/store/apps/details?id=io.holdstation"
                target="_blank"
                className="px-6 py-[22px] flex justify-center items-center gap-2 bg-[#1C1C1C] rounded-full"
              >
                <img src={chPlay} alt="apple" width={21} height={20} loading="lazy" />
                <p className="text-sm font-medium text-white">CH Play</p>
              </Link>
            </div>

            <div className="max-w-[301px] aspect-square w-full md:w-[160px] md:h-[160px] rounded-2xl overflow-hidden">
              <img src={qrcode} className="w-full h-full object-cover" alt="close" width={160} height={160} />
            </div>
          </div>
        </div>
      </ModalCenter>
    </>
  )
}
