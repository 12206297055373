import { BrowserEvent, InterfaceElementName, SwapEventName } from '@uniswap/analytics-events'
import { Percent } from '@uniswap/sdk-core'
import { TraceEvent, useTrace } from 'analytics'
import AnimatedDropdown from 'components/AnimatedDropdown'
import Column from 'components/Column'
import { RowBetween, RowFixed } from 'components/Row'
import { formatCommonPropertiesForTrade } from 'lib/utils/analytics'
import { useState } from 'react'
import { ChevronDown } from 'react-feather'
import { InterfaceTrade } from 'state/routing/types'
// import { isSubmittableTrade } from 'state/routing/utils'
import styled, { useTheme } from 'styled-components'
import { useFormatter } from 'utils/formatNumbers'

// import GasEstimateTooltip from './GasEstimateTooltip'
import SwapLineItem, { SwapLineItemType } from './SwapLineItem'

const StyledHeaderRow = styled(RowBetween)<{
  disabled: boolean
  open: boolean
}>`
  padding: 0;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

const SwapDetailsWrapper = styled(Column)`
  padding-top: ${({ theme }) => theme.grids.md};
`

const Wrapper = styled(Column)`
  border-radius: 16px;
  padding: 12px 16px;
`

interface SwapDetailsProps {
  trade?: InterfaceTrade
  syncing: boolean
  loading: boolean
  allowedSlippage: Percent
  refetch?: () => void
  typedValue: string
}

export default function SwapDetailsDropdown(props: SwapDetailsProps) {
  const { trade, syncing, loading, allowedSlippage } = props
  const theme = useTheme()
  const [showDetails, setShowDetails] = useState(false)
  const trace = useTrace()

  return (
    <Wrapper>
      <AdvancedSwapDetails {...props} open={showDetails} />

      <TraceEvent
        events={[BrowserEvent.onClick]}
        name={SwapEventName.SWAP_DETAILS_EXPANDED}
        element={InterfaceElementName.SWAP_DETAILS_DROPDOWN}
        properties={{
          ...(trade ? formatCommonPropertiesForTrade(trade, allowedSlippage) : {}),
          ...trace,
        }}
        shouldLogImpression={!showDetails}
      >
        <StyledHeaderRow
          data-testid="swap-details-header-row"
          onClick={() => setShowDetails(!showDetails)}
          disabled={!trade}
          width="100%"
          justify="center"
          open={showDetails}
        >
          <div className="flex justify-center items-center w-full mx-auto mt-3">
            <div className="bg-[#141414] h-[1px] flex-1 mr-4" />
            <p className="text-sm text-[#808080]">{showDetails ? 'Show less' : 'See details'} </p>
            <RotatingArrow stroke={trade ? theme.neutral3 : theme.surface2} open={Boolean(trade && showDetails)} />
            <div className="bg-[#141414] h-[1px] flex-1 ml-4" />
          </div>
          <RowFixed gap="xs">
            {/*{!showDetails && isSubmittableTrade(trade) && (*/}
            {/*  <GasEstimateTooltip trade={trade} loading={syncing || loading} />*/}
            {/*)}*/}
          </RowFixed>
        </StyledHeaderRow>
      </TraceEvent>
    </Wrapper>
  )
}

function AdvancedSwapDetails(props: SwapDetailsProps & { open: boolean }) {
  const { open, trade, allowedSlippage, syncing = false, typedValue } = props
  const format = useFormatter()

  if (!trade) return null

  const lineItemProps = { trade, allowedSlippage, format, syncing }

  return (
    <AnimatedDropdown open={open}>
      <SwapDetailsWrapper gap="md" data-testid="advanced-swap-details">
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.EXCHANGE_RATE} />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.PRICE_IMPACT} />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.MAX_SLIPPAGE} />

        {/*<SwapLineItem {...lineItemProps} type={SwapLineItemType.INPUT_TOKEN_FEE_ON_TRANSFER} />*/}
        {/*<SwapLineItem {...lineItemProps} type={SwapLineItemType.OUTPUT_TOKEN_FEE_ON_TRANSFER} />*/}
        {/*<SwapLineItem {...lineItemProps} type={SwapLineItemType.NETWORK_COST} />*/}
        {/*<SwapLineItem {...lineItemProps} type={SwapLineItemType.ROUTING_INFO} />*/}
      </SwapDetailsWrapper>
    </AnimatedDropdown>
  )
}
