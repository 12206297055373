import { useWeb3React } from '@web3-react/core'
import { gasPondAbi } from 'abis/gas-pond'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { PAYMASTER_LIST } from 'state/paymaster/initStore'
import { setPaymasterRateList } from 'state/paymaster/slices'
import { RateErc20 } from 'state/paymaster/type'
import { AppState } from 'state/reducer'

export async function getPricePoolRateAll(props: {
  rpcUrl: string
  // eslint-disable-next-line rulesdir/no-undefined-or
  provider: ethers.providers.Web3Provider | undefined
}) {
  const { rpcUrl, provider } = props
  if (!rpcUrl || !provider) {
    return undefined
  }
  try {
    const listAddress = PAYMASTER_LIST.map((item) => item.address)
    const listCall = listAddress.map((address: string) => {
      const gasIn = `0x${new BigNumber(Math.pow(10, 15)).toString(16)}`

      const gasPondContract = new ethers.Contract('0x4081e092F948Cffd946a75e1F556c13c372304bc', gasPondAbi, provider)
      return gasPondContract
        .getTokenFee(address, gasIn)
        .catch(() => [ethers.BigNumber.from(0), ethers.BigNumber.from(0)])
    })
    const responseList = await Promise.all(listCall)
    const data = responseList.reduce<Record<string, RateErc20>>((res, value, index) => {
      const selectedToken = PAYMASTER_LIST.find((x) => listAddress[index].toLowerCase() === x.address.toLowerCase())
      res[listAddress[index].toLowerCase()] = {
        before: new BigNumber(value[1].toHexString())
          .div(Math.pow(10, Number(selectedToken?.decimals ?? 18)))
          .multipliedBy(1000)
          .toNumber(),
        after: new BigNumber(value[0].toHexString())
          .div(Math.pow(10, Number(selectedToken?.decimals ?? 18)))
          .multipliedBy(1000)
          .toNumber(),
      }
      return res
    }, {})

    console.debug('data', data)
    return data
  } catch (error) {
    console.error(`error on : `, error)
    return undefined
  }
}

export function useGetRate() {
  const { provider } = useWeb3React()
  const dispatch = useAppDispatch()
  const rateList = useAppSelector((state: AppState) => state.paymasterStoreReducer.rateList)

  useEffect(() => {
    if (!provider || rateList) {
      return
    }
    ;(async () => {
      const data = await getPricePoolRateAll({ rpcUrl: 'https://mainnet.era.zksync.io', provider })
      if (!data) {
        return
      }
      dispatch(setPaymasterRateList(data))
    })()
  }, [dispatch, provider, rateList])

  return rateList
}
