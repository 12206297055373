export function WebView({ address }: { address: string }) {
  return (
    <div className="flex-1">
      <iframe
        src={`https://dexscreener.com/zksync/${address}?embed=1&theme=dark&trades=0&info=0`}
        width="100%"
        height={469}
      ></iframe>
    </div>
  )
}
