import { Trans } from '@lingui/macro'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import Column, { AutoColumn } from 'components/Column'
import { SmartRouterTrade } from 'lib/SwapRouter'
import { InterfaceTrade } from 'state/routing/types'
import { isPreviewTrade } from 'state/routing/utils'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'

import { Field } from './constants'
import { SwapModalHeaderAmount } from './SwapModalHeaderAmount'
import { useMemo } from 'react'

const HeaderContainer = styled(AutoColumn)`
  margin-top: 0px;
`

export function SwapPreview({
  trade,
  inputCurrency,
  allowedSlippage,
}: {
  trade: InterfaceTrade
  inputCurrency?: Currency
  allowedSlippage: Percent
}) {
  const fiatValueInput = useMemo(() => ({ data: (trade as SmartRouterTrade)?.amountInUsd, isLoading: !trade }), [trade])
  const fiatValueOutput = useMemo(
    () => ({ data: (trade as SmartRouterTrade)?.amountOutUsd, isLoading: !trade }),
    [trade]
  )

  return (
    <HeaderContainer gap="sm">
      <Column style={{ gap: '8px' }}>
        <div className="bg-[#191919] rounded-[16px] px-[24px] py-[16px]">
          <SwapModalHeaderAmount
            field={Field.INPUT}
            label={<Trans>You're paying</Trans>}
            amount={trade.inputAmount}
            currency={inputCurrency ?? trade.inputAmount.currency}
            usdAmount={fiatValueInput.data}
            isLoading={isPreviewTrade(trade) && trade.tradeType === TradeType.EXACT_OUTPUT}
          />
        </div>
        <div className="bg-[#191919] rounded-[16px] px-[24px] py-[16px]">
          <SwapModalHeaderAmount
            field={Field.OUTPUT}
            label={<Trans>To receive</Trans>}
            amount={trade.outputAmount}
            currency={trade.outputAmount.currency}
            usdAmount={fiatValueOutput.data}
            isLoading={isPreviewTrade(trade) && trade.tradeType === TradeType.EXACT_INPUT}
            tooltipText={
              trade.tradeType === TradeType.EXACT_INPUT ? (
                <ThemedText.Caption>
                  <Trans>
                    Output is estimated. You will receive at least{' '}
                    <b>
                      {trade.minimumAmountOut(allowedSlippage).toSignificant(6)} {trade.outputAmount.currency.symbol}
                    </b>{' '}
                    or the transaction will revert.
                  </Trans>
                </ThemedText.Caption>
              ) : (
                <ThemedText.Caption>
                  <Trans>
                    Input is estimated. You will sell at most{' '}
                    <b>
                      {trade.maximumAmountIn(allowedSlippage).toSignificant(6)} {trade.inputAmount.currency.symbol}
                    </b>{' '}
                    or the transaction will revert.
                  </Trans>
                </ThemedText.Caption>
              )
            }
          />
        </div>
      </Column>
    </HeaderContainer>
  )
}
