import BigNumber from 'bignumber.js'
import Loading from 'components/Loading/Loading'
import { WETH } from 'pages/AddLiquidity/EstimatedPoint'
import { usePoolDetailsByVolume } from 'pages/AllPoolDetail/datav2'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { LogoImage, formatBalance } from './all-pool'
import { ItemTableAllPool, PoolResponse } from './useQueryAllPool'

type Props = {
  index: number
  item: ItemTableAllPool | any
}

export default function ItemTable({ index, item }: Props) {
  const { data, loading } = usePoolDetailsByVolume(item.id)
  const navigation = useNavigate()
  const navigate = useCallback(
    (item: PoolResponse) => {
      return navigation(`/detail/${item.id}`)
    },
    [navigation]
  )

  const symbol1 = item.token0.id === WETH ? 'ETH' : item.token0.symbol
  const symbol2 = item.token1.id === WETH ? 'ETH' : item.token1.symbol

  const apr = useMemo(() => {
    const feeTier = new BigNumber(item.feeTier ?? 0).dividedBy(10_000).dividedBy(100)

    const apr = feeTier.multipliedBy(data ?? 0)

    const apr_percentage = apr.dividedBy(item.tvlUSD).multipliedBy(360).multipliedBy(1e2).toString() || '0'

    return apr_percentage
  }, [data, item.feeTier, item.tvlUSD])
  return (
    <tr key={index} className="cursor-pointer" onClick={() => navigate(item)}>
      <td className="text-base py-[20px] px-[20px]">{index + 1}</td>
      <td className="text-base py-[20px] flex items-center gap-1 ">
        <div className="w-9 h-6 flex items-center justify-end">
          <LogoImage id0={item.token0.id} id1={item.token1.id} />
        </div>

        <span className="truncate">{`${symbol1}/${symbol2}`}</span>
      </td>
      <td className="text-center text-base py-[20px]">${formatBalance(item.tvlUSD)}</td>
      <td className="text-center text-base py-[20px]">{apr === 'NaN' ? '--' : formatBalance(apr || '0')}%</td>
      <td className="text-center text-base py-[20px]">
        <Loading isLoading={loading} width={80} height={24}>
          <span> ${formatBalance(data?.toString() || 0)}</span>
        </Loading>
      </td>
      <td className="text-right text-base py-[20px] pr-[20px]">${formatBalance(item.volume7D.toString())}</td>
    </tr>
  )
}
