import { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { formatBalance } from '../all-pool'
import { useProtocolData } from './data'

export enum VolumeWindow {
  daily,
  weekly,
  monthly,
}

export function Overview() {
  const { fetchedProtocolData, loading, error } = useProtocolData()

  const { tvl, volume } = useMemo(() => {
    if (!fetchedProtocolData || error) {
      return {
        tvl: '--',
        volume: '--',
      }
    }

    return {
      tvl: formatBalance(String(fetchedProtocolData.tvlUSD)),
      volume: formatBalance(String(fetchedProtocolData.volumeUSD)),
    }
  }, [fetchedProtocolData, error])

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full border-t border-b border-t-[#141414] border-b-[#141414] md:mt-14">
        <div className="w-[1200px] grid grid-cols-2 items-center justify-between">
          <div className="flex flex-col gap-3 py-4">
            <Skeleton height={20} width={120} baseColor="#202020" highlightColor="#444" />
            <Skeleton height={36} width={120} baseColor="#202020" highlightColor="#444" />
          </div>

          <div className="flex flex-col gap-3 border-l border-l-[#141414] py-4 pl-12">
            <Skeleton height={20} width={120} baseColor="#202020" highlightColor="#444" />
            <Skeleton height={36} width={120} baseColor="#202020" highlightColor="#444" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center w-full border-t border-b border-t-[#141414] border-b-[#141414] md:mt-14">
      <div className="px-4 md:px-0 w-[1200px] grid grid-cols-2 items-center justify-between">
        <div className="flex flex-col gap-3 py-4">
          <p className="text-sm md:text-base text-[#808080]">TVL</p>
          <p className="text-[20px] md:text-[32px] md:leading-10">${tvl}</p>
        </div>

        <div className="flex flex-col gap-3 border-l border-l-[#141414] py-4 pl-4 md:pl-12">
          <p className="text-sm md:text-base text-[#808080]">Volume 24H</p>
          <p className="text-[20px] md:text-[32px] md:leading-10">${volume}</p>
        </div>
      </div>
    </div>
  )
}
