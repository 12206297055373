import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import clsx from 'clsx'
import { getChainInfo } from 'constants/chainInfo'
import { isSupportedChain } from 'constants/chains'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { Dispatch, SetStateAction } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSwapAndLimitContext } from 'state/swap/hooks'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

function Search(props: { search: string; setSearch: Dispatch<SetStateAction<string>> }) {
  const { search, setSearch } = props
  const { pathname } = useLocation()

  if (pathname === '/pool/my-pool') {
    return null
  }

  return (
    <div>
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        placeholder="Search Pools"
        className={clsx(
          'w-[149px] min-h-[44px] px-[14px] bg-[#141414]',
          'outline-none rounded-full text-[14px] placeholder:text-[#808080] font-medium'
        )}
      />
    </div>
  )
}

function ButtonAddLiquidity() {
  const switchChain = useSwitchChain()

  const { chainId: chainIdMeta } = useWeb3React()

  const { chainId } = useSwapAndLimitContext()

  const { connector } = useWeb3React()

  if (!isSupportedChain(chainIdMeta)) {
    return (
      <button
        onClick={async () => {
          try {
            if (chainId) {
              await switchChain(connector, chainId)
            }
          } catch (error) {
            if (didUserReject(error)) {
              // Ignore error, which keeps the user on the previous chain.
            } else {
              // TODO(WEB-3306): This UX could be improved to show an error state.
              throw error
            }
          }
        }}
        className="flex items-center gap-[8px] px-[20px] py-[14px] rounded-full bg-white text-[14px] leading-[16px] font-medium tracking-[-0.28px] text-black"
      >
        <span>Connect to {getChainInfo(chainId)?.label}</span>
      </button>
    )
  }

  return (
    <Link
      // to={'/add?currencyA=ETH'}
      to="/add"
      state={{ currencyA: 'ETH' }}
      className="flex items-center gap-[8px] px-[20px] py-[14px] rounded-full bg-white text-[14px] leading-[16px] font-medium tracking-[-0.28px] text-black"
    >
      <span>+</span>
      <span>
        <Trans>Add Liquidity</Trans>
      </span>
    </Link>
  )
}

export function PoolHeader(props: { search: string; setSearch: Dispatch<SetStateAction<string>> }) {
  const { pathname } = useLocation()

  return (
    <div className="mb-[24px] flex flex-col md:flex-row gap-4 md:items-center justify-between">
      <div className="flex items-center gap-[24px]">
        <Link
          to="/pool"
          className={clsx(
            'text-[18px] md:text-[24px] leading-[24x] md:leading-[32px] tracking-[-0.48px] text-[#4C4C4C]',
            {
              'text-white': pathname === '/pool',
            }
          )}
        >
          All Pools
        </Link>

        <Link
          to="/pool/my-pool"
          className={clsx(
            'text-[18px] md:text-[24px] leading-[24x] md:leading-[32px] tracking-[-0.48px] text-[#4C4C4C]',
            {
              'text-white': pathname === '/pool/my-pool' || pathname === '/detail/',
            }
          )}
        >
          My Pools
        </Link>
      </div>

      <div className="flex items-center gap-2">
        <ButtonAddLiquidity />

        <Search {...props} />
      </div>
    </div>
  )
}
