import clsx from 'clsx'
import Chart from 'pages/Pool/Overview/barchart'
import { unixToDate } from 'pages/Pool/Overview/overviewv2'
import { formatBalance } from 'pages/Pool/all-pool'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import DensityChart from './DensityChart'
import { usePoolChartData } from './data'

enum ChartView {
  VOL,
  PRICE,
  DENSITY,
  FEES,
}

const data = [
  { title: 'Volume', value: ChartView.VOL },
  { title: 'Liquidity', value: ChartView.DENSITY },
  { title: 'Fees', value: ChartView.FEES },
]

export function ChartDetail() {
  const [view, setView] = useState(ChartView.VOL)
  const [latestValue, setLatestValue] = useState<number | undefined>()
  const [valueLabel, setValueLabel] = useState<string | undefined>()

  const { id } = useParams<{ id?: string }>()

  const chartData = usePoolChartData(id ?? '')

  // const formattedTvlData = useMemo(() => {
  //   if (chartData) {
  //     return chartData.map((day) => {
  //       return {
  //         time: unixToDate(day.date),
  //         value: day.totalValueLockedUSD,
  //       }
  //     })
  //   } else {
  //     return []
  //   }
  // }, [chartData])

  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.volumeUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const formattedFeesUSD = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.feesUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const formattedValue = useMemo(() => {
    if (latestValue) {
      return `$${formatBalance(String(latestValue ?? 0))}`
    }

    switch (view) {
      case ChartView.VOL:
        return `$${formatBalance(String(formattedVolumeData[formattedVolumeData.length - 1]?.value ?? 0))}`

      case ChartView.DENSITY:
        return ''

      default:
        return `$${formatBalance(String(formattedFeesUSD[formattedFeesUSD.length - 1]?.value ?? 0))}`
    }
  }, [formattedFeesUSD, formattedVolumeData, latestValue, view])

  return (
    <div className="rounded-2xl border border-[#141414] p-4 flex flex-col justify-between">
      <div className="flex justify-between">
        <div>
          <p className="text-2xl text-white">
            <span>{formattedValue}</span>
          </p>
          <p
            className={clsx('text-xs', {
              'opacity-0': !valueLabel,
            })}
          >
            {valueLabel} (UTC)
          </p>
        </div>

        <div className="w-fit cursor-pointer h-[32px] flex items-center p-[2px] rounded-full bg-[#121212]">
          {data.map((item, idx) => {
            return (
              <button
                key={idx}
                onClick={() => setView(item.value)}
                className={clsx('px-[12px] py-[6px] rounded-full text-[12px] leading-[16px] text-[#808080]', {
                  'bg-[#1C1C1C]': view === item.value,
                })}
              >
                {item.title}
              </button>
            )
          })}
        </div>
      </div>
      {view === ChartView.VOL ? (
        <Chart
          data={formattedVolumeData}
          color="#DBFF73"
          minHeight={340}
          setValue={setLatestValue}
          setLabel={setValueLabel}
          value={latestValue}
          label={valueLabel}
        />
      ) : view === ChartView.FEES ? (
        <Chart
          data={formattedFeesUSD}
          color="#DBFF73"
          minHeight={340}
          setValue={setLatestValue}
          setLabel={setValueLabel}
          value={latestValue}
          label={valueLabel}
        />
      ) : (
        <DensityChart address={id ?? ''} />
      )}
    </div>
  )
}
