import { InterfacePageName } from '@uniswap/analytics-events'
import { Trace } from 'analytics'
import { createContext, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Overview2 from './Overview/overviewv2'
import { PoolHeader } from './pool-header'

export const SearchContext = createContext<string>('')

export function PoolV3() {
  const [search, setSearch] = useState('')

  const { pathname } = useLocation()

  // trigger when location change
  useEffect(() => {
    setSearch('')
  }, [pathname])
  return (
    <SearchContext.Provider value={search}>
      <Trace page={InterfacePageName.POOL_PAGE} shouldLogImpression>
        {/* <Overview /> */}

        <Overview2 />

        <section className="w-full max-w-[1232px] px-[16px] mt-[24px]">
          <PoolHeader search={search} setSearch={setSearch} />

          <Outlet />
        </section>
      </Trace>
    </SearchContext.Provider>
  )
}
