import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'

export interface TokenResponse {
  amount: string
  balance: string
  contract_address: string
  contract_decimals: number
  contract_name: string
  contract_ticker_symbol: string
  logo_url: string
  native_token: true
  quote: string
  quote_rate: string
  type: string
}

async function fetcher(account?: string) {
  if (!account) {
    return undefined
  }

  const url = `https://api.holdstation.com/api/user-balance/chain/324/wallet/${account}`

  const response = await fetch(url)

  if (!response.ok) {
    return undefined
  }

  const data = await response.json()
  return data.data
}

export function useGetTokens() {
  const { account } = useWeb3React()

  const data = useQuery<TokenResponse[]>(['get-account', account], () => fetcher(account), {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: 60000,
  })

  return data
}
