import Card from 'components/Card'
import { RowBetween } from 'components/Row'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import styled from 'styled-components'
import { VolumeWindow } from './overview'

dayjs.extend(utc)

const DEFAULT_HEIGHT = 300

const Wrapper = styled(Card)`
  width: 100%;
  height: ${DEFAULT_HEIGHT}px;
  padding: 1rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  > * {
    font-size: 1rem;
  }
`

export type LineChartProps = {
  data: any[]
  color?: string
  height?: number
  minHeight?: number
  setValue?: Dispatch<SetStateAction<number | undefined>> // used for value on hover
  setLabel?: Dispatch<SetStateAction<string | undefined>> // used for label of valye
  value?: number
  label?: string
  activeWindow?: VolumeWindow
  topLeft?: ReactNode
  topRight?: ReactNode
  bottomLeft?: ReactNode
  bottomRight?: ReactNode
} & React.HTMLAttributes<HTMLDivElement>

const CustomBar = ({
  x,
  y,
  width,
  height,
  fill,
}: {
  x: number
  y: number
  width: number
  height: number
  fill: string
}) => {
  if (isNaN(x) || isNaN(y) || isNaN(width) || isNaN(height)) {
    return null
  }
  return (
    <g>
      <rect x={x} y={y} fill={fill} width={width} height={height} rx="2" />
    </g>
  )
}

const Chart = ({
  data,
  color = '#56B2A4',
  setValue,
  setLabel,
  value,
  label,
  activeWindow,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  // minHeight = DEFAULT_HEIGHT,
  ...rest
}: LineChartProps) => {
  const parsedValue = value

  const now = dayjs()

  return (
    <Wrapper {...rest}>
      <RowBetween style={{ alignItems: 'flex-start' }}>
        {topLeft ?? null}
        {topRight ?? null}
      </RowBetween>
      {data?.length === 0 ? (
        <div>
          <Skeleton width="100%" height={36} baseColor="#202020" highlightColor="#444" borderRadius={12} />
          <Skeleton width="100%" height={36} baseColor="#202020" highlightColor="#444" borderRadius={12} />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            onMouseLeave={() => {
              setLabel && setLabel(undefined)
              setValue && setValue(undefined)
            }}
          >
            <XAxis
              dataKey="time"
              axisLine={false}
              tickLine={false}
              tickFormatter={(time) => dayjs(time).format(activeWindow === VolumeWindow.monthly ? 'MMM' : 'DD')}
              minTickGap={10}
            />
            <Tooltip
              cursor={{ fill: '#1C1C1C' }}
              contentStyle={{ display: 'none' }}
              formatter={
                ((value: number, name: string, config: { payload: { time: string; value: number } }) => {
                  if (setValue && parsedValue !== config.payload.value) {
                    setValue(config.payload.value)
                  }
                  const formattedTime = dayjs(config.payload.time).format('MMM D')
                  const formattedTimeDaily = dayjs(config.payload.time).format('MMM D YYYY')
                  const formattedTimePlusWeek = dayjs(config.payload.time).add(1, 'week')
                  const formattedTimePlusMonth = dayjs(config.payload.time).add(1, 'month')

                  if (setLabel && label !== formattedTime) {
                    if (activeWindow === VolumeWindow.weekly) {
                      const isCurrent = formattedTimePlusWeek.isAfter(now)
                      setLabel(
                        formattedTime + '-' + (isCurrent ? 'current' : formattedTimePlusWeek.format('MMM D, YYYY'))
                      )
                    } else if (activeWindow === VolumeWindow.monthly) {
                      const isCurrent = formattedTimePlusMonth.isAfter(now)
                      setLabel(
                        formattedTime + '-' + (isCurrent ? 'current' : formattedTimePlusMonth.format('MMM D, YYYY'))
                      )
                    } else {
                      setLabel(formattedTimeDaily)
                    }
                  }
                  return null
                }) as any
              }
            />
            <Bar
              dataKey="value"
              fill={color}
              shape={(props: any) => {
                return <CustomBar height={props.height} width={props.width} x={props.x} y={props.y} fill={color} />
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
      <RowBetween>
        {bottomLeft ?? null}
        {bottomRight ?? null}
      </RowBetween>
    </Wrapper>
  )
}

export default Chart
