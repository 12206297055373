import { memo } from 'react'
import { WebView } from './WebView'

function ChartInputComponent({ address }: { address: string }) {
  if (!address) {
    return null
  }

  return <WebView address={address} />
}
export const ChartInput = memo(ChartInputComponent)
