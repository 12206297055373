import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { formatDisplay } from 'utils/formatNumbers'
import { formatBalance } from '../all-pool'
import BarChart from './barchart'
import { useProtocolData } from './data'
import { useFetchGlobalChartData, useTransformedVolumeData } from './data/chart'
import LineChart from './line-chart'
import { VolumeWindow } from './overview'

dayjs.extend(utc)

export function unixToDate(unix: number, format = 'YYYY-MM-DD'): string {
  return dayjs.unix(unix).utc().format(format)
}

function Loading({ children, loading }: { loading: boolean; children: (loading: boolean) => ReactNode }) {
  return <>{children(loading)}</>
}

export default function Overview2() {
  const [volumeWindow, setVolumeWindow] = useState(VolumeWindow.daily)

  const { fetchedProtocolData: protocolData, loading } = useProtocolData()

  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [leftLabel, setLeftLabel] = useState<string | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()

  // Hot fix to remove errors in TVL data while subgraph syncs.
  const { data: chartData } = useFetchGlobalChartData()

  useEffect(() => {
    setLiquidityHover(undefined)
    setVolumeHover(undefined)
  }, [])

  // if hover value undefined, reset to current day value
  useEffect(() => {
    if (volumeHover === undefined && protocolData) {
      setVolumeHover(protocolData.volumeMonthUSD)
    }
  }, [protocolData, volumeHover])

  useEffect(() => {
    if (liquidityHover === undefined && protocolData) {
      setLiquidityHover(protocolData.tvlUSD)
    }
  }, [liquidityHover, protocolData])

  const formattedTvlData = useMemo(() => {
    if (chartData) {
      return chartData
        .filter((item) => {
          return new BigNumber(item.tvlUSD).gt(0)
        })
        .map((day) => {
          return {
            time: unixToDate(day.date),
            value: day.tvlUSD,
          }
        })
    } else {
      return []
    }
  }, [chartData])

  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData
        .filter((item) => new BigNumber(item.volumeUSD).gt(0))
        .map((day) => {
          return {
            time: unixToDate(day.date),
            value: day.volumeUSD,
          }
        })
    } else {
      return []
    }
  }, [chartData])

  const volumeData = useMemo(() => {
    if (!chartData) {
      return []
    }

    return chartData.filter((item) => {
      return new BigNumber(item.volumeUSD).gt(0)
    })
  }, [chartData])

  const weeklyVolumeData = useTransformedVolumeData(volumeData, 'week')
  const monthlyVolumeData = useTransformedVolumeData(volumeData, 'month')

  const tvlValue = useMemo(() => {
    if (liquidityHover || liquidityHover === 0) {
      return formatBalance(String(liquidityHover))
    }

    return formatBalance(String(protocolData?.tvlUSD ?? 0))
  }, [liquidityHover, protocolData?.tvlUSD])

  return (
    <div className="w-full max-w-[1232px] px-[16px] mt-[48px]">
      <p className="text-base font-medium text-white mb-3">Overview</p>

      <div className="flex flex-col md:flex-row items-stretch md:items-center gap-6">
        <div className="flex-1 bg-[#121212] rounded-2xl">
          <LineChart
            data={formattedTvlData}
            color="#DBFF73"
            height={220}
            minHeight={332}
            value={liquidityHover}
            label={leftLabel}
            setValue={setLiquidityHover}
            setLabel={setLeftLabel}
            topLeft={
              <div className="flex flex-col gap-2">
                <p className="text-sm md:text-base text-[#808080]">TVL</p>
                <p className="text-[20px] md:text-[32px] md:leading-10">${tvlValue}</p>
                <p
                  className={clsx('text-xs text-white', {
                    'opacity-0': !leftLabel,
                  })}
                >
                  {leftLabel} (UTC)
                </p>
              </div>
            }
          />
        </div>

        <div className="flex-1 bg-[#121212] rounded-2xl">
          <BarChart
            height={220}
            minHeight={332}
            color="#DBFF73"
            data={
              volumeWindow === VolumeWindow.monthly
                ? monthlyVolumeData
                : volumeWindow === VolumeWindow.weekly
                ? weeklyVolumeData
                : formattedVolumeData
            }
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={volumeWindow}
            topRight={
              <div className="flex items-center gap-2">
                <button
                  className={clsx('bg-[#121212] px-2 rounded-md', {
                    'bg-[#DBFF73] text-black': VolumeWindow.daily === volumeWindow,
                  })}
                  onClick={() => setVolumeWindow(VolumeWindow.daily)}
                >
                  D
                </button>

                <button
                  className={clsx('bg-[#121212] px-2 rounded-md', {
                    'bg-[#DBFF73] text-black': VolumeWindow.weekly === volumeWindow,
                  })}
                  onClick={() => setVolumeWindow(VolumeWindow.weekly)}
                >
                  W
                </button>

                <button
                  className={clsx('bg-[#121212] px-2 rounded-md', {
                    'bg-[#DBFF73] text-black': VolumeWindow.monthly === volumeWindow,
                  })}
                  onClick={() => setVolumeWindow(VolumeWindow.monthly)}
                >
                  M
                </button>
              </div>
            }
            topLeft={
              <div className="flex flex-col gap-2">
                <p className="text-sm md:text-base text-[#808080]">Volume</p>
                <p className="text-[20px] md:text-[32px] md:leading-10">${formatBalance(String(volumeHover ?? 0))}</p>
                <p
                  className={clsx('text-xs text-white', {
                    'opacity-0': !rightLabel,
                  })}
                >
                  {rightLabel} (UTC)
                </p>
              </div>
            }
          />
        </div>
      </div>

      <Loading loading={loading}>
        {(loadingValue) => {
          if (loadingValue) {
            return (
              <div className="hidden md:flex items-center gap-4 bg-[#121212] mt-4 p-4 rounded-2xl">
                <Skeleton width={120} height={20} baseColor="#202020" highlightColor="#444" />
                <Skeleton width={120} height={20} baseColor="#202020" highlightColor="#444" />
                <Skeleton width={120} height={20} baseColor="#202020" highlightColor="#444" />
              </div>
            )
          }

          return (
            <div className="hidden md:flex p-4 bg-[#121212] mt-4 rounded-2xl items-center gap-4">
              <p className="text-base text-white">
                Volume 24H: {formatBalance(String(protocolData?.volumeUSD ?? 0))}
                <span
                  className={clsx({
                    'text-[#EB5252]': new BigNumber(protocolData?.volumeUSDChange ?? 0).lt(0),
                    'text-[#49EB45]': new BigNumber(protocolData?.volumeUSDChange ?? 0).gt(0),
                  })}
                >
                  ({formatDisplay(protocolData?.volumeUSDChange ?? 0, { decimalToShow: 2 })}%)
                </span>
              </p>
              <p className="text-base text-white">
                Fees 24H: {formatBalance(String(protocolData?.feesUSD ?? 0))}
                <span
                  className={clsx({
                    'text-[#EB5252]': new BigNumber(protocolData?.feeChange ?? 0).lt(0),
                    'text-[#49EB45]': new BigNumber(protocolData?.feeChange ?? 0).gt(0),
                  })}
                >
                  ({formatDisplay(protocolData?.feeChange ?? 0, { decimalToShow: 2 })}%)
                </span>
              </p>
              <p className="text-base text-white">
                TVL: {formatBalance(String(protocolData?.tvlUSD ?? 0))}
                <span
                  className={clsx({
                    'text-[#EB5252]': new BigNumber(protocolData?.tvlUSDChange ?? 0).lt(0),
                    'text-[#49EB45]': new BigNumber(protocolData?.tvlUSDChange ?? 0).gt(0),
                  })}
                >
                  ({formatDisplay(protocolData?.tvlUSDChange ?? 0, { decimalToShow: 2 })}%)
                </span>
              </p>
            </div>
          )
        }}
      </Loading>
    </div>
  )
}
