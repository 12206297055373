import { ChainId } from '@uniswap/sdk-core'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TradeState } from 'state/routing/types'
import { useSwapAndLimitContext, useSwapContext } from 'state/swap/hooks'
import styled from 'styled-components'
import { FeatureFlags } from 'uniswap/src/features/experiments/flags'
import { useFeatureFlag } from 'uniswap/src/features/experiments/hooks'
import reload from '../../../assets/images/reload.svg'
import { RowBetween } from '../../Row'
import { SwapTab } from '../constants'
import { SlippageHeader } from './SlippageHeader'
import { ButtonChart } from './button-chart'
import './reload.css'

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 12px;
  padding-right: 4px;
  color: ${({ theme }) => theme.neutral2};
`

const PathnameToTab: { [key: string]: SwapTab } = {
  '/swap': SwapTab.Swap,
  '/send': SwapTab.Send,
  '/limit': SwapTab.Limit,
}

export default function SwapHeader({ compact }: { compact: boolean; syncTabToUrl: boolean }) {
  const limitsEnabled = useFeatureFlag(FeatureFlags.LimitsEnabled)
  // const sendEnabled = useFeatureFlag(FeatureFlags.SendEnabled) && !isIFramed()
  const { chainId, currentTab, setCurrentTab } = useSwapAndLimitContext()
  const {
    derivedSwapInfo: { trade, parsedAmount },
  } = useSwapContext()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const reloadRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (PathnameToTab[pathname] === SwapTab.Limit && (!limitsEnabled || chainId !== ChainId.MAINNET)) {
      navigate(`/${SwapTab.Swap}`, { replace: true })
      return
    }

    setCurrentTab(PathnameToTab[pathname] ?? SwapTab.Swap)
  }, [chainId, limitsEnabled, navigate, pathname, setCurrentTab])

  // Limits is only available on mainnet for now
  if (chainId !== ChainId.MAINNET && currentTab === SwapTab.Limit) {
    setCurrentTab(SwapTab.Swap)
  }

  const { state, refetch } = trade

  const [routeIsSyncing] = useMemo(() => [state === TradeState.LOADING && Boolean(trade)], [trade, state])

  const refetchQuote = useCallback(() => {
    if (refetch) {
      if (reloadRef.current) {
        reloadRef.current.classList.add('active')

        const id = setInterval(() => {
          reloadRef.current?.classList.remove('active')

          clearInterval(id)
        }, 1000)
      }

      refetch()
    }
  }, [refetch])

  return (
    <StyledSwapHeader>
      <div className="flex flex-1 items-center gap-[4px]">
        <ButtonChart />

        {!!parsedAmount && !routeIsSyncing && refetch && (
          <button
            ref={reloadRef}
            onClick={refetchQuote}
            className="px-[20px] py-[10px] rounded-full bg-[#121212] reload"
          >
            <img src={reload} alt="reload icon" width={20} height={20} loading="lazy" className="transition-all" />
          </button>
        )}
      </div>

      <div className="flex items-center gap-[4px]">
        <SlippageHeader compact={compact} trade={trade.trade} />

        {/* {currentTab === SwapTab.Swap && <SettingHeader />} */}
      </div>
    </StyledSwapHeader>
  )
}
