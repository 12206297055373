import Modal from 'components/Modal'
import { ethers } from 'ethers'
import { useWindowSize } from 'hooks/useWindowSize'
import { TokenPaymasterList } from './TokenPaymasterList'

type Props = {
  gasCost: ethers.BigNumber
  isOpen: boolean
  onClose: () => void
}

export default function ModalSwitchTokenGas({ gasCost, isOpen, onClose }: Props) {
  const { height: windowHeight } = useWindowSize()
  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onClose}
      height={Math.min(Math.round((680 / (windowHeight ?? 0)) * 100), 80)}
      onSwipe={() => {}}
    >
      <div className="flex flex-col py-6 max-h-[560px] w-full mb-10">
        <h1 className="text-center text-[24px] leading-[32px] tracking-[-0.4px] text-white mb-[8px]">Pay Gas By</h1>

        <p className="text-center text-[15px] leading-[20px] tracking-[-0.3px] text-[#808080]">
          Unlock exclusive discounts with paymaster!
        </p>

        <div className="h-[1px] w-full bg-[#1C1C1C] mt-[24px] mb-[8px]" />

        <TokenPaymasterList onClose={onClose} gasCost={gasCost} />
      </div>
    </Modal>
  )
}
