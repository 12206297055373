import styled from 'styled-components'
import { PoolData } from '../type'

const Wrapper = styled.div`
  border-radius: 8px;
  padding: 6px 12px;
  color: white;
  width: fit-content;
  font-size: 14px;
`

interface LabelProps {
  x: number
  y: number
  index: number
}

interface ChartEntry {
  index: number
  isCurrent: boolean
  activeLiquidity: number
  price0: number
  price1: number
  tvlToken0: number
  tvlToken1: number
}

interface CurrentPriceLabelProps {
  data?: ChartEntry[]
  chartProps: any
  poolData: PoolData
}

export function CurrentPriceLabel({ data, chartProps, poolData }: CurrentPriceLabelProps) {
  const labelData = chartProps as LabelProps
  const entryData = data?.[labelData.index]
  if (entryData?.isCurrent) {
    const price0 = entryData.price0
    const price1 = entryData.price1
    return (
      <g>
        <foreignObject x={labelData.x - 80} y={318} width="100%" height={100}>
          <div className="bg-[#1C1C1C] w-fit px-4 py-2 rounded">
            <div>
              <p className="text-sm">Current Price</p>
              <p className="text-sm">{`1 ${poolData.token0.symbol} = ${Number(price0).toLocaleString(undefined, {
                minimumSignificantDigits: 1,
              })} ${poolData.token1.symbol}`}</p>
              <p className="text-sm">{`1 ${poolData.token1.symbol} = ${Number(price1).toLocaleString(undefined, {
                minimumSignificantDigits: 1,
              })} ${poolData.token0.symbol}`}</p>
            </div>
          </div>
        </foreignObject>
      </g>
    )
  }
  return null
}
