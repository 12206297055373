import sorticon from 'assets/svg/sort.svg'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import Loading from 'components/Loading/Loading'
import { useToken } from 'hooks/Tokens'
import useDebounce from 'hooks/useDebounce'
import { usePoolDetailsByVolume } from 'pages/AllPoolDetail/datav2'
import { formatDisplay } from 'pages/Swap/Chart/format-balance'
import { ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async/lib/index'
import InfiniteScroll from 'react-infinite-scroll-component'
import { unwrappedToken } from 'utils/unwrappedToken'
import ItemTable from './ItemTable'
import { SearchContext } from './PoolV3'
import { LoadingTable } from './loading-table'
import { useQueryAllPool } from './useQueryAllPool'

const UGOLD = '0x10d967f46b06580c4a87b05c78f04e4df25c0db0'
const GOLD = '0x437bea54e2a6b154ab1efa866a939a97574e8940'

const FILTERS = [UGOLD, GOLD]
/**
 * negative value return 0
 * @param num positive or negative number
 */
export function formatBalance(num: string) {
  const data = new BigNumber(num).toNumber()

  if (new BigNumber(num).lt(0)) {
    return '0'
  }

  if (new BigNumber(num).gte(1_000_000_000)) {
    return formatDisplay(data / 1_000_000_000, { decimalToShow: 1 }) + 'B'
  }

  if (new BigNumber(num).gte(1_000_000)) {
    return formatDisplay(data / 1_000_000, { decimalToShow: 1 }) + 'M'
  }

  if (new BigNumber(num).gte(1_000)) {
    return formatDisplay(data / 1_000, { decimalToShow: 1 }) + 'K'
  }

  return formatDisplay(data, { decimalToShow: 2, minimumDecimalToShow: 2 })
}

const FIRST = 20

export function LogoImage({ id0, id1 }: { id0: string; id1: string }) {
  // SUPPORT ONLY 324
  const token0 = useToken(id0, 324)
  const token1 = useToken(id1, 324)

  const currency0 = useMemo(() => (token0 ? unwrappedToken(token0) : undefined), [token0])
  const currency1 = useMemo(() => (token1 ? unwrappedToken(token1) : undefined), [token1])

  return <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
}

function compareValues(a: any, b: any, key: string, isAsc: boolean) {
  const diff = new BigNumber(b[key]).minus(a[key]).toNumber()

  return isAsc ? -diff : diff
}

function TRow<T>({ data, children }: { data: T; children: (data: T) => ReactNode }) {
  const content = children(data)

  return <>{content}</>
}

function Volume24H({ address }: { address: string }) {
  const { data, loading } = usePoolDetailsByVolume(address)

  return (
    <Loading isLoading={loading} width={80} height={24}>
      <span> ${formatBalance(data?.toString() || 0)}</span>
    </Loading>
  )
}

export function AllPool() {
  const [sort, setSort] = useState<'desc' | 'asc'>('desc')
  const [sortType, setSortType] = useState(0)
  const { data, loading, fetchMore } = useQueryAllPool('desc')

  const search = useContext(SearchContext)

  const searchDebounce = useDebounce(search, 500)

  function toggleSort(type: 0 | 1 | 2 | 3) {
    if (sort === 'asc') {
      setSort('desc')
    } else {
      setSort('asc')
    }

    setSortType(type)
  }

  const allpools = useMemo(() => {
    if (!data?.pools || !data.bundles?.[0]?.ethPriceUSD) return []

    const filteredItems = data.pools.filter((pool) => {
      const isInFilter = FILTERS.some(
        (v) => v.toLowerCase() === pool.token0.id.toLowerCase() || v.toLowerCase() === pool.token1.id.toLowerCase()
      )
      if (isInFilter) {
        return false
      }

      return (
        pool.token0.symbol.toLowerCase().includes(searchDebounce) ||
        pool.token1.symbol.toLowerCase().includes(searchDebounce)
      )
    })

    const enrichedPools = filteredItems.map((item) => {
      const volume7D = new BigNumber(item.volumeUSD)

      const feePercent = new BigNumber(item?.feeTier ?? 0).dividedBy(10000).dividedBy(100)
      const tvlAdjust0 = new BigNumber(item.volumeToken0).multipliedBy(feePercent).dividedBy(2)

      const tvlAdjust1 = item?.volumeToken1 ? new BigNumber(item.volumeToken1).multipliedBy(feePercent).dividedBy(2) : 0
      const tvlToken0 = new BigNumber(item.totalValueLockedToken0).minus(tvlAdjust0)
      const tvlToken1 = new BigNumber(item.totalValueLockedToken1).minus(tvlAdjust1)

      let tvlUSD = new BigNumber(item.totalValueLockedUSD).toNumber()
      const ethPriceUSD = parseFloat(data.bundles[0].ethPriceUSD)

      // Part of TVL fix
      const tvlUpdated =
        tvlToken0.multipliedBy(item.token0.derivedETH).multipliedBy(ethPriceUSD).toNumber() +
        tvlToken1.multipliedBy(item.token1.derivedETH).multipliedBy(ethPriceUSD).toNumber()

      if (tvlUpdated) {
        tvlUSD = tvlUpdated
      }

      return { ...item, volume7D, tvlUSD }
    })

    return enrichedPools.sort((a, b) => {
      switch (sortType) {
        case 0:
          return compareValues(a, b, 'totalValueLockedUSD', sort === 'asc')
        case 1:
          return compareValues(a, b, 'volumeUSD', sort === 'asc')
        case 2:
          return compareValues(a, b, 'volume7D', sort === 'asc')
        case 3:
          return compareValues(a, b, 'apr', sort === 'asc')
        default:
          return 0
      }
    })
  }, [data?.bundles, data?.pools, searchDebounce, sort, sortType])

  const hasMore = useMemo(() => {
    if (!data?.pools) {
      return false
    }

    return data?.pools?.length % FIRST === 0
  }, [data?.pools])

  const handleNext = useCallback(() => {
    if (!hasMore) {
      return
    }

    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev
        }

        return {
          pools: [...prev.pools, ...fetchMoreResult.pools],
          bundles: prev.bundles,
        }
      },
      variables: {
        skip: data?.pools?.length ?? 0,
        first: FIRST,
      },
    })
  }, [hasMore, fetchMore, data?.pools])

  if (loading) {
    return <LoadingTable />
  }

  return (
    <>
      <Helmet>
        <title>Holdstation | Liquidity Pools</title>
      </Helmet>

      <InfiniteScroll
        dataLength={data?.pools?.length ?? 0}
        next={handleNext}
        hasMore={hasMore}
        loader={<h4 className="text-center py-4"></h4>}
      >
        <div className="rounded-2xl border border-[#171717] overflow-auto">
          <table className="w-full border-collapse table-fixed min-w-[800px]">
            <thead>
              <tr className="[&>th]:border-[#171717] [&>th]:border-b-[1px] [&>th]:text-base [&>th]:text-[#4C4C4C] [&>th]:whitespace-nowrap">
                <th className={clsx('text-left px-[20px] py-[20px] font-normal w-[8%]')}>#</th>
                <th className="text-left py-[20px] font-normal">Pool</th>
                <th className="py-[20px] font-normal">
                  <div
                    className="px-2 grid grid-cols-[24px_min-content] justify-center cursor-pointer"
                    onClick={() => toggleSort(0)}
                  >
                    <img
                      className="flex-shrink-0"
                      src={sorticon}
                      alt="sort icon"
                      width={24}
                      height={24}
                      loading="lazy"
                    />
                    <span>TVL</span>
                  </div>
                </th>

                <th className="py-[20px] font-normal">
                  <div
                    className="px-2 grid grid-cols-[24px_min-content] justify-center cursor-pointer"
                    onClick={() => toggleSort(3)}
                  >
                    <img
                      className="flex-shrink-0"
                      src={sorticon}
                      alt="sort icon"
                      width={24}
                      height={24}
                      loading="lazy"
                    />
                    <span>APR</span>
                  </div>
                </th>

                <th className="py-[20px] font-normal">
                  <div
                    className="px-2 grid grid-cols-[24px_min-content] justify-center cursor-pointer"
                    onClick={() => toggleSort(1)}
                  >
                    <img
                      className="flex-shrink-0"
                      src={sorticon}
                      alt="sort icon"
                      width={24}
                      height={24}
                      loading="lazy"
                    />
                    <span>Volume (24h)</span>
                  </div>
                </th>
                <th className="text-right py-[20px] font-normal pr-[20px]">
                  <div
                    className="grid grid-cols-[24px_min-content] justify-end cursor-pointer"
                    onClick={() => toggleSort(2)}
                  >
                    <img
                      className="flex-shrink-0"
                      src={sorticon}
                      alt="sort icon"
                      width={24}
                      height={24}
                      loading="lazy"
                    />
                    <span> 7 Day volume</span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <TRow data={allpools}>
                {(data) => {
                  if (data.length === 0) {
                    return (
                      <tr>
                        <td colSpan={6} className="text-base text-[#808080] text-center py-10">
                          No data
                        </td>
                      </tr>
                    )
                  }

                  return (
                    <>
                      {allpools.map((item, idx) => {
                        return <ItemTable key={idx} index={idx} item={item} />
                      })}
                    </>
                  )
                }}
              </TRow>
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </>
  )
}
